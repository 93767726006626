import { bgColors } from 'backgammon_ui_shared';
import styled, { CSSObject } from 'styled-components';
import { getStaticResource } from '../../../../../shared/api';

interface Props {
  disabled: boolean;
  isTabOrScreen: boolean;
}

export const MenuItemButton = styled.button<Props>(
  ({ disabled, isTabOrScreen }): CSSObject => ({
    alignItems: 'center',
    backgroundColor: bgColors.textPrimary,
    border: 0,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'column',
    height: isTabOrScreen ? 150 : 100,
    position: 'relative',
    justifyContent: 'space-evenly',
    margin: '8px',
    transition: 'filter 0.2s',
    width: isTabOrScreen ? 150 : 100,
    ...(disabled
      ? { opacity: 0.7 }
      : {
          '&:hover': { filter: 'brightness(0.9)' },
          '&:active': { filter: 'brightness(0.8)' },
        }),
  }),
);

export const MenuItemIcon = styled.div<{ iconPath: string; isTabOrScreen: boolean }>(
  ({ iconPath, isTabOrScreen }): CSSObject => ({
    width: isTabOrScreen ? 96 : 64,
    height: isTabOrScreen ? 96 : 64,
    backgroundImage: `url(${getStaticResource(iconPath)})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    backgroundSize: 'contain',
  }),
);

export const MenuItemTitle = styled.span<{ isTabOrScreen: boolean }>(
  ({ isTabOrScreen }): CSSObject => ({
    color: bgColors.buttonDefault,
    fontSize: isTabOrScreen ? 21 : null,
  }),
);

export const BetaRibbon = styled.div`
  --f: 5px; /* control the folded part*/
  --r: 10px; /* control the ribbon shape */
  --t: 10px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: ${bgColors.blunder};
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
  color: ${bgColors.textPrimary};
`;
